import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Card, Typography, CardHeader, CardContent } from '@mui/material';

// utils
import axios from '../../../../utils/axios';
import { PHARMACY_API } from '../../../../constants/ApiPaths';

// ----------------------------------------------------------------------

ShipmentInfo.propTypes = {
    shipmentInfo: PropTypes.object,
};

export default function ShipmentInfo({ shipmentInfo }) {
    // console.log({shipmentInfo})
  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader style={{ marginBottom:-10,marginTop:-20}} title="Shipment Info" />
      <CardContent>
        <Typography style={{ marginBottom:0}} variant="subtitle2" gutterBottom>
          {shipmentInfo.pharmacy_detail?.name || ''}
        </Typography>
        <Typography style={{ marginBottom:2}} variant="subtitle2" gutterBottom>
          Pharmacy Name: &nbsp;
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {shipmentInfo.pharmacy_name}
          </Typography>
        </Typography>

        <Typography style={{ marginBottom:2}} variant="subtitle2" gutterBottom>
          Buy Name: &nbsp;
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {shipmentInfo.buy_name}
          </Typography>
        </Typography>

        <Typography style={{ marginBottom:2}} variant="subtitle2" gutterBottom>
          Order Name: &nbsp;
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {shipmentInfo.order_name}
          </Typography>
        </Typography>

        <Typography style={{ marginBottom:2}} variant="subtitle2" gutterBottom>
          Shipment Number: &nbsp;
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            {shipmentInfo.shipment_number}
          </Typography>
        </Typography>

      </CardContent>
    </Card>
  );
}
